.split-container {
  flex: 1;
}

.top-part {
  position: relative;

  .hide-show-btn {
    align-items: center;
    border-radius: 4px 0 0 4px !important;
    bottom: 5px;
    color: #fff;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    letter-spacing: 1px;
    position: absolute;
    right: 0;
    top: 5px;
    width: 26px;
    z-index: 10;

    &:focus, &:active {
      box-shadow: none !important;
    }
  }

  .calendar-control-bar~.hide-show-btn {
    bottom: 39px;
  }
}

.scrollable {
  flex: 1;
  position: relative;

  .inner {
    background: #fff;
    border-top: 4px solid #fff !important;
    bottom: 0;
    overflow-y: scroll;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.split-right {
  background-color: $toolbar_bg2;

  .top-part {
    height: 86px;
  }
}

.split-right, .split-left {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.SplitPane {
  position: initial !important;
}

.Resizer {
  background: #000;
  z-index: 1;
  -webkit-background-clip: padding;
     -moz-background-clip: padding;
          background-clip: padding-box;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;

  &:hover {
    -webkit-transition: all 2s ease;
            transition: all 2s ease;
  }

  &.horizontal {
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    border-top: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    height: 11px;
    margin: -5px 0;
    width: 100%;

    &:hover {
      border-bottom: 5px solid rgba(0, 0, 0, 0.5);
      border-top: 5px solid rgba(0, 0, 0, 0.5);  
    }
  }

  &.vertical {
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
    margin: 0 -5px;
    width: 11px;

    &:hover {
      border-left: 5px solid rgba(0, 0, 0, 0.5);
      border-right: 5px solid rgba(0, 0, 0, 0.5);
    }
  }

  &.disabled {
    cursor: not-allowed;

    &:hover {
      border-color: transparent;
    }
  }
}

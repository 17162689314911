.autosave-header {
  border-bottom: 1px solid #fff;
  color: #999;
}

.autosave-header-nosave {
  background-color: $pt-readonly-bg;
  margin: 0;
  padding: 0.25rem 0;
}

.autosave-header-ro-msg {
  align-items: center;
  display: flex;
  margin-top: 2px;
}

// protouree status display in the footer
.pt-activity-status-container {
  float: right;
}

// actouree status display inside the edit mode alert
.at-activity-status-container {
  display: inline-block;
}

.activity-status {
  border-radius: 5px;
  box-sizing: border-box;
  display: inline-block;
  margin-right: 4px;
  text-align: center;
}

// add some padding for the status messages in the protouree footer
.pt-activity-status {
  margin: 2px 4px 0 0;
  padding: 0 6px;
}

.activity-status-message {
  background-color: #706d6d;
  border: 1px solid #000;
  border-radius: 3px;
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-size: 0.8rem;
  margin-right: 12px;
  padding: 0 6px;
  text-align: center;
  text-transform: capitalize;
  vertical-align: text-top;
}

.activity-error {
  background-color: #f00;
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  margin-right: 12px;
  padding: 0 6px 2px 6px;
  text-align: center;
  vertical-align: top;
}

.as-idle {
  background-color: transparent ;
}

.as-active {
  color: #00b42d !important;
}

.pt-data-changed {
  background-color: rgb(255, 4, 4);
  color: #fff;
}

.at-data-changed {
  color: rgb(255, 4, 4);
}

.data-initial {
  background-color: transparent;
}

.data-saved {
  background-color: rgb(0, 129, 11);
  color: #fff;
}

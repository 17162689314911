// Overriding time and date labels
.dates-label {
    display: inline-block;
    width: 34px;
    font-weight: bold;
}

.times-label {
    display: inline-block;
    width: 20px;
    font-weight: bold;
}


.times-selector-row {
    margin-top: 6px;
}

.recurs-days {
    display: inline-block;
    list-style-type: none;

    li {
        display: inline;
    }
}
// Overriding time picker width setting
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width:85px;
}
.react-datepicker__header {
  //  background-color: #ccc;
    background-color: #bdbdbd;
}
// .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
//     background-color: $orange;
//     color:#000;
//     font-weight: bold;
// }
.react-datepicker__day--selected {
    background-color: $orange !important;
    color:#000 !important;
    font-weight: bold !important;
    
}
.react-datepicker__day--keyboard-selected {

    background-color: $pt-mid-grey !important;
    color:#000 !important;

}
// over riding time picker container
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
    float: left;
    margin-left: 0px !important;
    margin-right: 7px;
}

// the small copy date button which appears when creating new events
.datepicker-copy-date-btn {
    padding: 2px 5.25px;
    line-height: 1;
    height: 20px;
    margin-top: 7px;
}

.react-datepicker__day--outside-month {

    color: $pt-info-light !important;
    border: 1px;
  
  }


.pt-card {
  .card-header {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .panel-heading {
    margin-bottom: 1rem;
    padding-bottom: 0.75rem;
  }

  &.primary-card {
    .card-header {
      background: $plum;
      color: $white;
    }

    .panel-heading {
      border-bottom: 1px solid $plum;
      color: $plum;
    }
  }

  &.secondary-card {
    .card-header {
      background: $teal;
      color: $white;
    }

    .panel-heading {
      border-bottom: 1px solid $teal;
      color: $teal;
    }
  }
}

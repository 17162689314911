.at-validated-indicator {
    @include font-size(1.3rem);
    left: 0;
    top: $at-header-height;
    padding: 1rem;
    position: absolute;
    width: 100%;
    z-index: 2;
    
    &.text {
        color: #fff;
    }
}

.evaluation-container {
    margin-top: $at-header-height;
}

.fg-valid-colour {
    color: #86cc2a;
}

.bg-valid-colour {
    background: #86cc2a;
}

.fg-invalid-colour {
    color: #d60f3a;
}

.bg-invalid-colour {
    background: #d60f3a;
}

.fg-missing-colour {
    color: #929292;
}

.bg-missing-colour {
    background: #929292;
}

.at-help {
	padding-left: 1.4rem;
	padding-right: 1.4rem;

	label {
		display: inline-block;
		font-weight: bold;
		margin-right: 6px;
		margin-bottom: 0;
	}

	// sub-section headings
	h3 {
		@include font-size(1.4rem);
		color:rgb(26, 25, 25);
		font-weight: 700;
		padding: 1rem;
	}

	h6 {
		@include font-size(1.2rem);
		font-weight: 700;
	}

	// panels that appear at the top of the event details - event summary, dates and times and booking

	.at-help-info-panel {
		padding-left: 1.4rem;
		padding-right: 1.4rem;

		h3 {
			@include font-size(1.5rem);
			border-bottom: 1px solid rgba(77, 77, 77,0.8) !important;
			font-weight: 700;
			margin-bottom: 0.5rem;
			padding-bottom: 0.5rem;
		}

		.form-group {
			margin-bottom: 0.5rem;
		}
	}

	.at-help-section {
		padding-left: 1rem;

		.booking-event {
			margin-bottom: 0.4rem;
			padding: 4px;
		}

		.card {
			.blocking {
				min-height: 70px !important;
			}
		}

		.event-title2 {
			background: rgba(255, 255, 255, 0.7);
			font-size: 85%;
			font-weight: 600;
			margin: 0;
			overflow: hidden;
			padding: 3px;
			text-overflow: ellipsis;
			white-space: pre-wrap;
		}
	}

	.lead-person {
		background-color: #ffb833 !important;
		color: rgb(61, 61, 61);
	}
}

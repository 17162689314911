.info {
  label {
    display: inline-block;
    font-weight: bold;
    margin-bottom: 0;
    margin-right: 6px;
  }

  h3 {
    @include font-size(1.4rem);
    color: rgb(26, 25, 25);
    font-weight: 700;
    padding: 1rem;
  }

  h6 {
    @include font-size(1.2rem);
    font-weight: 700;
  }

  // panels that appear at the top of the event details - event summary, dates and times and booking

  .info-panel {
    .form-group {
      margin-bottom: 0.5rem;
    }
  }

  .info-panel h2 {
    @include font-size(1.2rem);
    border-bottom: 1px solid rgba($pt-black, 0.3) !important;
    font-weight: 700;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .event-info-panel {
    background: #fff;
    margin-top: 1.5rem !important;
  }

  // specific styles for the single event modal

  &-modal-container {
    padding-left: 1.6rem;
    padding-right: 1.6rem;

    .heading-panel {
      background: $hilite-duo;
      background-clip: border-box;
      border: 1px solid rgba(77, 77, 77, 0.8) !important;
      border-radius: 0.25rem;
      display: flex;
      flex-direction: column;
      height: 100%;
      min-width: 0;
      padding: 1rem;
      position: relative;
      word-wrap: break-word;
    }

    .contacts-panel {
      background-clip: border-box;
      border: 1px solid rgba(77, 77, 77, 0.8) !important;
      border-radius: 0.25rem;
      height: 100%;
      min-width: 0;
      padding: 1rem;
      word-wrap: break-word;

      h6 {
        @include font-size(1rem);
        font-weight: 700;
      }
    }

    .at-title {
      background-color: #cecece;
      padding: 6px !important;
    }

    .bookings-panel {
      background: rgba(255, 255, 255, 0.5);
      background-clip: border-box;
      border: 1px dashed rgba(77, 77, 77, 0.8) !important;
      border-top-left-radius: 0.6rem !important;
      border-top-right-radius: 0.6rem !important;
      height: 100%;
      min-width: 0;
      padding: 1rem;
      word-wrap: break-word;

      h6 {
        @include font-size(1rem);
        font-weight: 700;
      }
    }
  }

  // specific styles for the day view

  &-day-container {
    background: #cecece;

    .booking-heading {
      color: $purple !important;
      padding-bottom: 1rem;
      padding-left: 2.75rem;
      position: relative;

      .ico-lmed {
        color: $purple;
        left: 0;
        position: absolute;
      }
    }

    .open-by-default.card-header,
    .pt-card .card-header {
      background: $pt-light-grey !important;
      color: $pt-black !important;
    }

    .heading-panel {
      background: rgba(0, 80, 120, 0.1);
      background-clip: border-box;
      border: 1px solid rgba(77, 77, 77, 0.5) !important;
      border-radius: 0.25rem;
      padding: 1rem;
      position: relative;
      word-wrap: break-word;

      @include media-breakpoint-up(md) {
        height: 100%;
      }

      @include media-breakpoint-down(sm) {
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    .contacts-panel {
      background-clip: border-box;
      border: 1px solid rgba(77, 77, 77, 0.5) !important;
      border-radius: 0.25rem;
      height: 100%;
      min-width: 0;
      padding: 1rem;
      word-wrap: break-word;
      
      h6 {
        @include font-size(1rem);
        font-weight: 700;
      }
    }

    .at-title {
      background-color: #cecece;
      padding: 6px !important;
    }

    .booking-container {
      background: #fff;
      border-radius: 0.25rem;
      border-top: 10px solid rgba(77, 77, 77,0.8) !important;
      margin-left: 2rem;
      margin-right: 2rem;
      padding: 1rem 10px 10px;

      .no-bg > .card-header {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
      }

      .booking-heading {
        @include font-size(1.6rem);
      }

      @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .standalone-container {
      background: #fff;
      margin-top: 30px;
      padding: 10px;
      border-top: 5px dashed rgb(255, 128, 10);
      border-radius: 0.25rem;
    }

    .emphasis {
      background: #fff; 
    }

    &-summary-panel {
      background: #fff;
      border-top: 5px solid rgb(173, 173, 173);
      border-bottom: 5px solid rgb(173, 173, 173);
      border-radius: 0.25rem;
      position: relative;
      word-wrap: break-word;

      h1 {
        @include font-size(1.4rem);
      }

      .form-group {
        margin: 0;
      }

      .ico-xl {
        @include media-breakpoint-down(md) {
          font-size: 3rem;
        }
      }
    }
  }

  .label-text {
    margin-top: 0.25rem;
  }

  .ico-event {
    margin-right: 0.5rem;
  } 
}

.at-underline {
  border-bottom: 1px solid #bbbbbb;
}

.at-topline {
  border-top: 1px solid #bbbbbb;
}

.at-big-badge {
  @include font-size(102%);
  padding: 4px;
}

.comms-table {
  margin-bottom: 0;

  td {
    a {
      word-wrap: break-word;
    }
  }

  .label-col {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include media-breakpoint-up(sm) {
    table-layout: fixed;
    width:100%;
  }
}

.at-accom-table {
  th {
    border-top: 1px solid rgba(0, 0, 0, 0.3) !important;
    padding: 0.75rem;
    vertical-align: top;
  }

  thead th {
    background-color: rgba(0, 0, 0, 0.125);
    border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;
    vertical-align: bottom;
  }
}

.at-badge-md {
  font-size: 138% !important;
  font-weight: normal !important;

  .badge {
    padding: 5px 7px !important;
  }
}

.at-event-alert {
  background: rgba(0, 0, 0, 0.15);
  border-color: rgba(0, 0, 0, 0.125);
  color: #333;
}

.at-time-limit-alert {
  color: rgb(255, 0, 0) ;
  font-size: 1.5rem;
  font-weight: 700 !important;
}

.at-booking-event-status-icon {
  font-size: 1.22rem !important;
}
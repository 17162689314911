.d-TOV {
    display: none;
}

.text-only-view {
    font-size: 1.8rem;
    line-height: 1.8;

    * {
        background: $white !important;
        color: $pt-black !important;
    }

    h1 {
        font-size: 3.5rem !important;
    }

    h2 {
        font-size: 3rem !important;
    }

    h3 {
        font-size: 2.6rem !important;
    }

    h4 {
        font-size: 2.4rem !important;
    }

    h5 {
        font-size: 2.2rem !important;
    }

    h6 {
        font-size: 2rem !important;
    }

    .fs-5 {
        font-size: 1.8rem;
    }

    .d-TOV {
        display: inline-block;
    }

    .d-TOV-none {
        display: none !important;
    }

    .mr-TOV-3 {
        margin-right: 1rem;
    }

    .px-TOV-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .px-TOV-2 {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }

    .TOV-one-line {
        div, .col, [class*="col-"] {
            display: inline-block !important;
            width: unset;
        }
    }

    .TOV-d-block {
        display: block !important;
    }

    .TOV-d-flex {
        display: flex !important;
    }

    .card {
        margin-bottom: 3rem;

        &.tech,
        &.travel,
        &.accomm,
        &.reh,
        &.press,
        &.photo,
        &.patron,
        &.null {
            border-bottom-width: 18px;
        }
    }

    .pt-card .card-header {
        font-size: 2.5rem;
    }

    .text-label,
    .TOV-one-line .col-3,
    .TOV-one-line .col-sm-3 {
        margin-right: 2rem;
        max-width: 150px;
        min-width: 150px;
    }

    .at-person-display {
        font-size: 2rem !important;
    }

    .heading-panel {
        padding-left: 0;
    }

    .panel-heading {
        border-color: $border-color !important;
    }

    .pt-accordion-dayschedule.open-by-default {
        background: $white !important;
        border-bottom: 1px solid $border-color !important;
        color: $pt-black !important;
        font-size: 2.75rem;
    }

    .info-modal-container .heading-panel {
        border: none !important;
    }

    .booking-container *,
    .info-day-container-summary-panel * {
        margin-left: 0 !important;
    }

    .at-title  {
        padding-left: 0 !important;
    }

    .row {
        display: block;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .col, [class*="col-"] {
        flex: none;
        max-width: unset;
        padding-left: 0;
        padding-right: 0;
    }

    [class*="icon-"] {
        display: none !important;
    }

    &.info-day-container {
        .booking-heading {
            padding-left: 1.5rem;
        }

        .booking-container .card-header {
            padding-bottom: 1.75rem;
            padding-top: 1.75rem;
        }

        .info-panel > h5 {
            margin-bottom: 1rem;
            padding-bottom: 1rem;
        }

        .heading-panel,
        .contacts-panel {
            border: none !important;
        }
    }
}

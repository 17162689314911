.table {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;

    &.no-thead {
        thead {
            display: none;
        }
    }
}

.table th,
.table td {
    padding: 0.4rem;
}

.react-bootstrap-table {
    overflow-y: initial !important;

    th { 
        background-color: rgb(247, 247, 247);
        font-size: 90% !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
    }

    td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
    }

    tr {
        cursor: pointer;

        &:hover {
            background-color: $orange-lite !important;
        }

        &.pt-selected-row {
            background-color: $orange !important;
            &:hover {
                background-color: $orange !important;
            }
        }
    }

    .filter-label {
        border-radius: 4px !important;
        padding: 1px;
    }
}

.pt-table-hscroll {
    overflow-x: scroll;
}

.pt-items-total-number {
    background-color: #fff !important;
    border: 0px;
    color: $pt-med-grey !important;
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 0px;
    padding: 0px 6px 6px 6px;
}

.react-bootstrap-table {
    thead th {
        background: $teal;
        border-bottom-color: #fff !important;
        border-left-color: #fff !important;
        border-right-color: #fff !important;
        color: #fff;
        text-align: center;
        vertical-align: middle;
    }

    tbody th {
        background: rgba(0, 0, 0, 0.05);
        border-left-color: #fff !important;
        border-right-color: #fff !important;
        border-top-color: #fff !important;
    }

    .table-striped tbody tr:nth-of-type(odd) {
        background: rgba($teal, 0.1);
    }

    .table-bordered {
        border-color: rgba(0, 0, 0, 0.15);

        th, td {
            border-color: rgba(0, 0, 0, 0.15);
        }
    }

    tr:hover {
        background: #fffbb3 !important;
    }
}

.btn {
    &.btn-link {
        background: transparent !important;
        border: none !important;

        &:focus {
            box-shadow: none;
        }
    }

    &.btn-context-help {
        align-items: center;
        background: $white;
        color: $blue;
        display: inline-flex;
        font-size: 1.5rem;
        height: 20px;
        justify-content: center;
        width: 20px;
    }
}

.btn-primary {
    &:hover {
        background-color: darken($blue, 10%);
    }
}

.btn-secondary {
    color: $white !important;

    &:hover {
        background-color: #939393;
        border-color: #939393;
    }
}

.btn-editor {
    background: $editor-btn-col;
    border-color: $editor-btn-col;
    color: $white !important;

    &:hover {
        background: darken($editor-btn-col, 8%);
        border-color: darken($editor-btn-col, 8%);
    }
}

.btn-teal {
    background: $teal;
    color: $white;

    &:hover {
        background: lighten($teal, 5%);
        color: $white;
    }
}

.btn-outline-secondary {
    border-color: #b0b0b0 !important;
}

.btn-white {
    background: $white;
    border-color: $border-color;
}

.dropdown-toggle.btn {
    border-radius: 0.25rem !important;

    &:after {
        margin-left: 0.5rem;
    }
}

.btn-delete-row {
    padding-bottom: 5px !important;
    margin: 0 auto;
}

.btn-link {
    color: $blue !important;

    &.active {
        color: $blue !important;
    }
}

.pt-disabled-button {
    opacity: 0.4 !important;
    outline: none !important;
    
    button {
        outline: none !important;
        cursor: not-allowed !important;
    }

    button:focus {
        outline: none !important;
    }
        
    &:hover {
        outline: none !important;
    }

    &:active {
        outline: none !important;
    }
}

.pt-delete-bin {
    button {
        outline: none !important;
        cursor: not-allowed !important;
    }

    button:focus {
        outline: none !important;
    }
        
    &:hover {
        outline: none !important;
    }

    &:active {
        outline: none !important;
    }
}

.pt-disabled-bin {
    opacity: 0.3 !important;
    
    button {
        outline: none !important;
        cursor: not-allowed !important;
    }

    button:focus {
        outline: none !important;
    }
        
    &:hover {
        outline: none !important;
    }
    
    &:active {
        outline: none !important;
    }
}

.pt-list-buttons {
    .dropdown {
        display: inline;
    }
}

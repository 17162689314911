.actouree-footer { 
  background: #fff;
  border-top: 1px solid #ddd;
  width: 100%;
  z-index: 1;

  .nav {
    > li {
      width: 25%;
    }

    a {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: $at-footer-height;
      justify-content: center;

      i {
        font-size: 2.5rem;

        &.icon-list2 {
          font-size: 2.25rem;
        }
      }

      &.active {
        color: $orange;
      }

      &:hover {
        background: rgba(0,0,0,0.1);
        text-decoration: none;
      }
    }
  }
}
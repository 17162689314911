.admin-category-tree-container {
    height: 120vh;
    margin-bottom: 60px;
    position: relative;
}

.admin-category-tree {
    height: 100%;
    overflow-x: hidden;
}

.pt-editor-tab-content {
    margin-top: 10px;
}

.pt-validated{
    color: $green;
}

.pt-staff-col {
    background-color: rgb(161, 161, 161) !important;  
}

.at-top {
    background-color: $at-header;
    box-sizing: border-box;
    margin-bottom: 0;
    padding: 0 1rem;
    width: 100%;
}

.at-logo-container {
    height: 100%;
}

.at-menu {
    color: #555;
    cursor: pointer;
    font-size: 2rem;

    &:hover {
        color: #cecece;
        text-decoration: none;
    }

    &:visited {
        color: #fff;
    }
}

.at-menu-h {
    background: #cecece;
    border-bottom: 2px solid #9a9a9a;
    color: #000000;
    font-size: 1.3rem;
    margin-bottom: 1rem;
    padding-top: 10px;
    padding-bottom: 8px;
}

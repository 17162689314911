.travel {
  border-color: #b09bdb;
  border-bottom-width: 4px;

  &:not(.no-bg) {
    background-color: #dfcfff;
  }

  &.no-bg {
    .modal-header,
    > .card-header {
      background-color: #dfcfff;
    }
  }
}

.accomm {
  border-color: #f0a6d6;
  border-bottom-width: 4px;

  &:not(.no-bg) {
    background-color: #ffdff6;
  }

  &.no-bg {
    .modal-header,
    > .card-header {
      background-color: #ffdff6;
    }
  }

  table.comms-table {
    margin-top: 10px !important;

    th {
      border-top: 1px solid rgba(0, 0, 0, 0.3) !important;
      border-bottom: 2px solid rgba(0, 0, 0, 0.3) !important;
    }

    a {
      color: #1a53cf;
      font-weight: 700;
    }
  }
}

.tech {
  border-color: #bdab98;
  border-bottom-width: 4px;

  &:not(.no-bg) {
    background-color: #d7cfc6;
  }

  &.no-bg {
    .modal-header,
    > .card-header {
      background-color: #d7cfc6;
    }
  }
}

.reh {
  border-color: #dfb561;
  border-bottom-width: 4px;

  &:not(.no-bg) {
    background-color: $hilite;
  }

  &.no-bg {
    .modal-header,
    > .card-header {
      background-color: $hilite;
    }
  }
}

.press {
  border-color: #61d3ba;
  border-bottom-width: 4px;

  &:not(.no-bg) {
    background-color: #88ffdd;
  }

  &.no-bg {
    .modal-header,
    > .card-header {
      background-color: #66ffdd;
    }
  }
}

.photo {
  border-color: #83e695;
  border-bottom-width: 4px;

  &:not(.no-bg) {
    background-color: #d0ffd0;
  }

  &.no-bg {
    .modal-header,
    > .card-header {
      background-color: #d0ffd0;
    }
  }
}

.patron {
  border-color: #39b9f5;
  border-bottom-width: 4px;

  &:not(.no-bg) {
    background-color: #caf3ff;
  }

  &.no-bg {
    .modal-header,
    > .card-header {
      background-color: #caf3ff;
    }
  }
}

.meeting {
  border-color: #82f000;
  border-bottom-width: 4px;

  &:not(.no-bg) {
    background-color: #e0ff90;
  }

  &.no-bg {
    .modal-header,
    > .card-header {
      background-color: #e0ff90;
    }
  }
}

// temporary style for day marker ( old misc style )
.day {
  background-color: rgb(255, 253, 223);
  border: 0.3rem dashed rgb(139, 139, 139);
}

.blocking {
  $lightColor: rgba(241, 241, 241, 0.589);
  $stripWidth: 12px;
  background-image: repeating-linear-gradient(135deg, 
    transparent,
    transparent $stripWidth, 
    $lightColor $stripWidth, 
    $lightColor ($stripWidth * 2)
  );
  background: rgb(209, 209, 209);
  border: 0.3rem dashed rgb(236, 63, 121);
  height: 100%;
  min-height: 200px;
}

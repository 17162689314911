.logged-in {
  background-color: #c659cd;
  border-radius: 25px 0px 0px 25px;
  height: 38px;
  margin-right: auto;

  &-user {
    color: #fff;
    margin: 9px 4px 0px 0px;

    .pt-login-icon {
      color: #fff !important;
      font-size: 180%;
      margin-left: 5px;
      margin-right: 14px;
      text-decoration: none !important;
      vertical-align: middle;
    }
  }
}

.pt-login-dark {
  color: $pt-alt-text;
}

.white-circle {
  background: #fff;
  border-radius: 50%;
  display: inline-block;
}

$ticket-circle-diameter: 120px;

.pt-app-select-page .ticket-panel {
  background: $gradient-purple;
  min-height: 260px;
  padding: 8rem 14rem;
  position: relative;
  width: 800px;

  .btn-link {
    font-weight: bold;
  }

  .white-circle {
    height: $ticket-circle-diameter;
    margin-top: -($ticket-circle-diameter/2);
    position: absolute;
    top: 50%;
    width: $ticket-circle-diameter;
    z-index: 1;

    &.left {
      left: -($ticket-circle-diameter/2);
    }

    &.right {
      right: -($ticket-circle-diameter/2);
    }
  }

  @include media-breakpoint-down(md) {
    padding: 8rem 14rem;
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    margin-top: $ticket-circle-diameter/2;
    padding: 8rem 3rem;

    .white-circle {
      margin-left: -($ticket-circle-diameter/2);
      left: 50% !important;
      transform: scale(0.8);

      &.left {
        top: 0;
      }

      &.right {
        bottom: -($ticket-circle-diameter/2);
        left: auto;
        top: auto;
      }
    }
  }
}

.app-select {
  background: lighten($teal, 39%);

  &-label {
    display: inline-block;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 2px;
  }

  .app-btn {
    span {
      color: #fff;
      padding-bottom: 2px;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(160, 144, 255, .5);
    }
  }
}

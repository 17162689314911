// colors
// $blue: #3399ff;
// $red: #ff1c67;
// $green:#1cff71;
// $orange: #ffd76a;
// $orange-lite: #fff2ad;
// $glow: #ffb039;
// $toolbar_bg: #ebebeb;
// $pt-info-light: #bdbdbd;
// // for actouree display highlight:
// $hilite: #fcf8ec;
// $hilite-duo: #f9efd2;
// // colours for view data 
// $view-data1: #f5ffe2;
// $view-data2: #d0ff79;
// $pt-dark-grey: #6c757d;
// $pt-form-border: #cecece;
// modify bootstraps theme colours

$hilite: #f9f2ea;
$hilite-duo: #ebdec8; 

$at-header-height: 54px;
$at-footer-height: 60px;

$at-header-light: #AA15AF;
$at-header-dark: #7D2580;

$at-header: #fff;

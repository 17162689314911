.pt-header {
  margin-bottom: 0;
  min-height: 42px;
  padding: 0 !important;
  width: 100%;

  .navbar-collapse {
    @include media-breakpoint-up(md) {
      height: 100%;
      margin-left: 4px;
    }
  }
}

.pt-nav {
  background-color: #b0b0b0;
  a {
    color: #fff;

    &.active {
      background-color: $pt-nav !important;
      color: #666 !important;
      cursor: default;
    }

    &:hover {
      background-color: $blue;
      color: #fff;
    }
  }

  @include media-breakpoint-up(md) {
    height: 100%;

    a {
      font-size: 1rem;
      height: 100%;
      padding: 10px 14px;
      text-transform: uppercase;
    }
  }
}

.navbar-collapse.collapse.show {
  display: block;
}

.navbar .logged-in {
  position: absolute;
  right: 0;
  top: 0;
  
  @include media-breakpoint-up(md) {
    margin-right: 0;
    position: unset;
    
    .logged-in-user {
      margin-right: 0;
      padding-right: 0 !important;
    }
  }
}

.navbar-nav .nav-item+.nav-item {
  margin-left: 0;
}

.logo {
  max-width: 50px;
}

.navigation {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  flex: 0;
  flex-flow: row nowrap;
  margin-right: auto;
  position: relative;
  width: 100%;
}

@media (min-width: 1024px) {
  .navbar-toggler {
    display: none;
  }

  .navbar-collapse {
    display: inline-block !important;
    flex: auto !important;
    margin-left: 1rem;
  }

  .navbar-nav {
    flex-direction: row !important;

   .nav-item+.nav-item {
      margin-left: 1rem;
    }
  }
}


.pt-logo-header {
  margin: 0 1rem;
}
// icon in date field
.pt-dateInput {
    padding-left: 20px;
    border: 1px solid #ced4da;
    width: 100px;
}

i.icon-calendar1 {
    color: #666;
    left: 5px;
    position: absolute;    
}

// icon in time field
.pt-timeInput {
    padding-left: 20px;
    border: 1px solid #ced4da;
    width: 80px;
}

i.icon-clock {
    color: #666;
    left: 5px;
    position: absolute;
}

// percentage symbol in number fields
.pt-percentage-wrapper {
    position: relative;

    span {
        color: rgb(105, 105, 105);
        height: 18px;
        margin-top: -9px;
        position: absolute;
        right: 5px;
        top: 50%;
    
        &:after {
            content: '%';
            display: block;
        }
    }
}

.pt-percentageInput {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    color: #495057;
    display: block;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    height: calc(1.5em + .75rem + 2px);
    line-height: 1.5;
    margin: 0;
    overflow: visible;
    padding-right: 18px !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
}

.pt-percentage-display {
    display: inline-block;
    margin-right: 4px;
}

// currency symbol in money fields
.pt-currency-wrapper { 
    position: relative;

    span {
        color: #333;
        height: 18px;
        left: 5px;
        margin-top: -9px;
        position: absolute;
        top: 50%; 

        &:after {
            content: '£';
            display: block;
        }
    }
}

.pt-currencyInput {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    color: #495057;
    display: block;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    height: calc(1.5em + .75rem + 2px);
    line-height: 1.5;
    margin: 0;
    overflow: visible;
    padding-left: 18px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
}

.pt-currency-display {
    display: inline-block;
    margin-left: 4px;
    padding-left: 15px;
    position: relative;
    
    span {
        color: rgb(105, 105, 105);
        height: 16px;
        margin-top: -8px;
        position: absolute;
        left: 5px;
        top: 50%;
    
        &:after {
            content: '£';
            display: block;
        }
    }
}

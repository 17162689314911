.pt-globals-on {
    background-color: $glow;
    border: 0px !important;

    &:hover {
        background-color: $blue;
        border: 0px !important;
        color:  #fff;
    }
}

.pt-globals-off {
    background-color: #fff;
    border: 1px solid $pt-dark-grey;

    &:hover {
        background-color: $blue;
        border: 1px solid #fff !important;
        color:  #fff;
    }
}

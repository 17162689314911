.editor-container {
  background-color: $toolbar_bg;
  bottom: 0;
  height: 100%;
  margin-bottom: 40px;
  top: 0;
  width: 100%;
}

.editor-panel {
  background-color: #fff;
  overflow: hidden;
  padding: 4px 10px;

  .label-col {
    max-width: 130px !important;
  }
}

.editor-tab {
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  padding: 10px 6px 0; 

  .nav-item {
    margin-right: -1px;

    .nav-link {
      background-color: $white;
      border: 1px solid $pt-form-border;
      font-size: 1.1rem;
      font-weight: bold;
      padding-top: 8px;

      &:hover {
        background-color: $blue;
        border-color: $blue;
        color: #fff;
        text-decoration: underline dotted;
      }

      &.active {
        border-color: $pt-form-border;
        border-top-color: $orange;
        border-top-width: 4px;
        border-bottom-color: #fff;
        color: #333;
        cursor: default;
        padding-top: 5px;
        text-decoration: none;

        &:hover {
          background-color: $white;
        }
      }
    }
  }
}

.editor-section {
  margin-top: 10px;

  h3, h4 {
    color: #666;
    font-weight: bold;
    margin-bottom: 0;
  }

  h3 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1.1rem;
  }
}

// day schedule styles for inside accordions on main editor
.pt-event-sections {
  width: 100%;
  border-top: 1px solid rgb(206, 206, 206);

  h5 {
    color: #666;
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 0;
  }
}

.people-list {
  > li {
    font-size: 1rem;
    padding: 0;
    position: relative;

    button {
      padding-left: 1.2rem !important;
      padding-right: 1rem !important;
    }

    &:before {
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 4px;
    }

    &.project-person {
      &:before {
        background: lighten($teal, 5%);
      }
    }

    &.people-lead {
      &:before {
        background: $orange;
      }
    }

    &.people-tour-manager {
      &:before {
        background: $orange;
      }
    }

    // if a non-project person has been added to an event this class is used to highlight them in the list
    // &.people-additional {}

    // projects company as set up in admin
    // &.pt-p-company {}
  }
}

// in the people selector - this class is added to people in the list who are
// a part of the project if the people selector is triggered from an event
.project-person {
  color: $blue !important;
  font-size: 1rem;
}

.pt-recurs {
  padding-top: 10px;
  border-top: 1px solid rgb(187, 187, 187);
}

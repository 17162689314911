.nav-tabs .nav-link {
    cursor: pointer; 
}

.pt-tabs-bckgrnd {
    align-items: flex-end;
    background: $toolbar_bg2;
    display: flex;
    height: 40px;
    padding-top: 4px;
    
    .nav-link {
        border-top: 4px solid $toolbar_bg2;
        
        &:not(.active):hover {
            background-color: $blue;
            border-color: $blue;
            border-top: 4px solid $blue;
            color: #fff;
            text-decoration: underline dotted;
        }
        
        &.active {
            border-bottom: 1px solid #fff;
            border-left: 1px solid $pt-form-border;
            border-right: 1px solid $pt-form-border;
            border-top: 4px solid $orange;
            color: #333;
            cursor: default;
            text-decoration: none;
        }
    }
}

.pt-editor-tab-pane {
    border-left: 1px solid $pt-form-border !important;
}

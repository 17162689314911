// *** Variables
@import 'variables';

// *** Vendor
@import 'bootstrap-variables-redefined';
@import 'bootstrap';

// *** Font awesome
// @import '../../node_modules/font-awesome/scss/font-awesome.scss';

// *** Base styles
@import 'base';

@import "icons";

// *** Components
@import 'header';
@import 'split-screen';
@import 'calendar';
@import '../common/product-types';
@import '../common/day_schedule_types';
@import 'booking-info-panel';
@import 'toolbar';
@import 'tabs';
@import 'footer';
@import 'loading';
@import 'booking-editor';
@import 'contacts';
@import 'tables';
@import 'feedback';
@import 'buttons';
@import 'editors-common';
@import 'editor-header';
@import 'lists';
@import 'tasks';
@import 'alerts';
@import 'summaries';
@import 'actouree';
@import 'modals';
@import 'reporting';
@import 'admin';
@import 'custom-values';
@import 'date-picker';
@import 'globals';
@import 'autosave';
@import 'login';
@import 'financials';
@import "contact-search";
@import "comms-cats";
@import "accordion";
@import "card";
@import "staff";
@import "project-selector";
@import "../actouree_scss/main.scss";
@import "text-only-view";

.container-fluid {
    min-height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
}
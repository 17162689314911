.at {
    &-person-display {
        @include font-size(1rem);
        font-weight: 700;
        margin-right: 4px;
        margin-top: 3px;
        padding: 4px 8px 5px 8px !important;
    }
    
    &-project-person {
        background: #6c757d;
        color: #fff;
    }
    
    &-non-project-person {
        background-color: #FFFFFF;
        border: 1px solid #6c757d;
        color: #6c757d;
    }

    &-person-id {
        font-size: 0.75rem;
    }
}

.actouree-container {
    .fc-button-group {
        margin: 4px 4px;
    }

    .fc-left, .fc-right {
        padding: 0;
    }

    .fc-button-group,
    .fc-left,
    .fc-right {
        .fc-button {
            min-width: 46px;
            min-height: 40px;
        }
    }
}

body, html {
  font-size: 12px;
  height: 100%;
  scroll-behavior: smooth;
  // overflow: hidden;
}

.blue {
  color: $blue;
}

.gray-700 {
  color: $gray-700;
}

.bg-teal {
  background: $teal !important;
}

.bg-table-teal-light {
  background: $table-teal-light;
}

.bg-light-blue {
  background: lighten($blue, 40%);
}

.bg-light-pink {
  background: #ffebff;
}

.badge-orange {
  color: desaturate(darken($orange, 46%), 10%) !important;
  background: desaturate(lighten($orange, 4%), 8%) !important;
}

.col-grey {
  color: $gray-600 !important;
}

.col-purple {
  color: $purple !important;
}

.col-plum {
  color: $plum !important;
}

.col-blue {
  color: $blue !important;
}

.col-teal {
  color: $teal !important;
}

.col-red {
  color: $red !important;
}

.h-0 {
  height: 0;
}

.w-0 {
  width: 0 !important;
}

.fs-lg {
  font-size: $font-size-lg;
}

.pt-1px {
  padding-top: 1px;
}

.my-1px {
  margin-bottom: 1px;
  margin-top: 1px;
}

.mt--1 {
  margin-top: -0.25rem;
}

.flex-1 {
  flex: 1;
}

.rotate--90 {
  -webkit-transform: rotate(-90deg);  
      -ms-transform: rotate(-90deg);  
          transform: rotate(-90deg);
}

.content-wrapper {
  padding: 0 1rem;
}

.instruction {
  display: block;
  font-size: 0.8rem;
  opacity: 0.8;
}

.overflow-scroll {
  overflow-x: scroll;

  table {
    margin-bottom: 0;
  }
}

.overflow-y-scroll {
  overflow-y: scroll;
}

ul.unstyled {
  list-style: none;
  padding-left: 0;
}

.fs-5 {
  font-size: 1.125rem;
}

.accordion-svg {
  max-width: 20px;
  display: inline-block;
  margin-right: 6px;

  svg {
    opacity: 0.5;
  }
}

.react-bootstrap-table {
  height: 100%;
  overflow-y: scroll;
}

.main-container {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.tab-content {
  border-left: 1px solid #cecece;
  border-right: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
}

.card-header .btn-sm {
  font-size: 0.875rem !important;
}

label {
  font-weight: bold;
}

.full-width-dd .dropdown-toggle {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  background-position: right 1.4rem center;
}

.pt-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pt-seperator {
  border-top: dotted 2px #999;
  padding-top: 20px;
  padding-bottom: 20px;
}

.pt-map-badge {
  background-color: #ea4335 !important;
  border-radius: 20rem !important;
  padding: 3px 5px;
	vertical-align: text-top;
}

.ico-acc.grey {
  color: #666;
}

.text-label {
  display: inline-block;
  font-weight: bold;
  margin-right: 1rem;
  max-width: 110px;
  min-width: 110px;
}

@include media-breakpoint-down(sm) {
  .card-body {
    padding-left: 10px;
    padding-right: 10px;
  }

  .overflow-scroll-down-sm {
    overflow-x: scroll;
  }
}
.actouree {
  &-container {
    flex: 1;
    overflow: hidden;
    position: relative;
  }
  
  &-content {
    height: calc(100% - #{$at-header-height} - #{$at-footer-height});
    overflow: hidden scroll;
  }
}

.categories-list {
	list-style: none;
	padding-left: 1rem;

	> li {
		margin: 0;
		padding: 0;
	}
}

.contact-tab {
	background-color: #fff;
}

.contact-people-container {
	background-color: #fff;
	padding: 0px;

	.card {
		background-color: $pt-light-grey;
		margin-bottom: 10px;
	}
}

.contact-display-container {
	background-color: #fff;
	padding: 0px;
}

.pt-viewer-card {
	background-color: $pt-light-grey;
	margin-bottom: 20px;
	margin-right: 10px;
	margin-left: 10px;
	padding: 20px;
}

.contacts-search-list-row {
    background-color: $toolbar_bg;
}

.pt-modal-notes {
	background-color: $toolbar_bg;
	border: 1px solid rgb(189, 189, 189);
}

.pt-modal-notes-alt {
	background-color: rgb(226, 226, 226);
	border: 1px solid rgb(189, 189, 189);
}

.contact-comms-table {
	textarea.form-control {
		height: calc(1.5em + 0.75rem + 2px);
	}

	td {
		padding-left: 2px;
		padding-right: 2px;
		vertical-align: middle !important;
	}

	tr.private {
		background-color: #ffb79f;

		&:hover {
			background-color: #ec9375;
		}
	}
}

// Comms category badges
.commcat-badge {
	display: block !important;
	margin-bottom: 4px;
	padding: 2px !important;
}

.commcat-badge-lg {
	@extend .commcat-badge;
	padding: 6px !important;
}

// Organisational category badges

.orgcat-badge {
	margin-right: 4px;
	padding: 4px !important;
}

// contact viewer styles
.cv-label {
	display: block;
	font-weight: 700;
}

.cv-title {
	div {
		width: 100%
	}
}
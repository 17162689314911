.pt-modal {
   .modal-body {
      font-size: 130%;
      padding: 30px;
   }

   h3 {
     color: #999;
   }
}

.modal-dialog {
   margin-bottom: 3rem;
}

.info-modal-container {
   h4.booking-heading {
      color: $purple !important;
      padding-left: 2rem;
      position: relative;

      .ico-lmed {
         left: 0;
         position: absolute;
      }
   }

   @include media-breakpoint-down(sm) {
      padding-left: 10px !important;
      padding-right: 10px !important;

      .heading-panel {
         padding-left: 8px !important;
         padding-right: 8px !important;
      }
   }
}

.react-draggable {
   .modal-content {
      border: 2px dashed rgba($plum, 0.8);
      cursor: move;
   }

   .modal-header {
      padding-right: 3rem;
      position: relative;

      span[class^='icon'] {
         color: $plum;
         height: 1.4rem;
         margin-top: -0.7rem;
         position: absolute;
         right: 1rem;
         top: 50%;

         &:before {
            font-size: 1.4rem;
         }
      }
   }
}


.contact-search-row {
    cursor: pointer;

    &:hover {
        background-color: #eff3b0;
    }
}

// .contact-search-title {
//     box-sizing: border-box;
//     padding: 4px;
//     margin-top: 6px;
//     font-size: 16px;
//     font-weight: bold;
//     background-color: #dfdfdf;
// }

// .contact-search-thead {
//     background-color: #e7f3ff;
// }
.contact-search-title {
    box-sizing: border-box;
    padding: 4px;
    margin-top: 6px;
    font-size: 16px;
    font-weight: bold;
    background-color: #ffdf9b;
}

.contact-search-thead {
    background-color: #cfd4d8;
}
.pt-contact-bullet {
    Table{
        ul {
            list-style-type: none;
            padding-left:0px;
            text-align: left !important;
            float: left !important;
            // font-size: 1.4rem;
            // margin-left:0px;
                li {
                    color: $blue !important;
                    margin-top:0px;
                    padding-top:0px;

                }


        }
    }
}
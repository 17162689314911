.lr-title {
  h5 {
    margin-bottom: 0;
    width: 100%;
  }

  .modal-title .event-title {
    align-items: flex-start;
    display: flex;
    padding-left: 1rem;
    position: relative;

    .title-text {
      font-weight: bold;
      margin-right: 1rem;

      span[class*="icon"] {
        margin-right: 0.8rem;
      }
    }

    .id-data {
      font-size: 1.25rem;

      .icon-user-cancel {
        position: relative;
        top: 2px;
      }
    }
  }
}

// comms cats links badge style
.commcat-link {
    cursor: pointer;
}

// organisational category link plus "button"
.orgcat-link {
    cursor: pointer;

    &:hover {
        color: $blue;
    }
}
.alert {
    [class*=" icon-"] {
        left: 1rem;
        margin-top: 3px;
        position: absolute;
    }
}

.pleasewait-text {
    font-size: 1.4rem;
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
}

.pt-alert-historical-data {
    background-color: $pt-light-grey;
    color: $pt-dark-grey;
    border: 0.3rem dotted rgb(172, 172, 172);

    ul {
        padding-left: 10px;
        
    }
}

.pt-cancelled {}

.pt-financials .table,
.pt-financials.table {
    thead th {
        border-bottom: none;
    }

    tbody td {
        border-color: #fff;
    }
}

.pt-financials {
    thead {
        background: $table-teal-light;
        border-top: 0px;

        .pt-financials-section {
            background: $table-bg-head;
            border: none;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            color: #fff;
            padding-left: 10px;
        }
    }

    td {
        background: lighten($table-teal-light, 15%);
        border-left: solid 1px #fff;
        border-right: solid 1px #fff;
        padding-right: 10px;
    }

    .pt-first-col {
        border-left: none;
        padding-left: 10px;
    }

    .pt-invoice-field-header {
        background: #b29bbe;
    }

    .pt-invoice-field-col,
    .pt-final-col {
        background: $table-bg-purple-light;
    }
}

.pt-invoice {
    background: $table-bg-purple-light;
    border: none;

    > .card-header {
        background: $table-bg-head;
        border: 1px solid $table-bg-head;
        border-radius: 4px 4px 0 0;
        color: #fff;
    }

    > .card-body {
        border: 1px solid lighten($table-bg-head, 38%);
        border-width: 0 1px;
    }

    > .card-footer {
        background: lighten($table-teal-light, 15%);
        border: 1px solid desaturate($table-teal-light, 20%);
        border-radius: 0 0 4px 4px;
    }

    .form-control {
        font-size: 1rem;
        height: calc(1.5em + 0.75rem + 2px);
        line-height: 1.5;
        padding: 0.375rem 0.75rem;
    }

    select {
        border: none !important;
    }
}

.pt-invoice-block {
    background: transparent;
    border: none;

    .card-header {
        background: transparent;
        border: none;
    }

    .card-body {
        background: lighten($table-bg-purple-light, 3%);
        border: 1px solid rgba(0, 0, 0, 0.125)
    }
}

.pt-disabled {
    background: rgb(187, 162, 202) !important;
}

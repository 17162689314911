.rpt-multi-select {
    margin-bottom: 4px;
}

.rpt-field-name {
    color: #cecece;
}

.rpt-control-container {
    background-color: #ececec;
    margin: 10px auto;
    padding: 10px;

    label, input, button, select {
        display: inline-block !important;
        margin-right: 4px;
        vertical-align: middle;
    }

    select {
        width: 20%;
    }

    p {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }

    .rpt-run-btn, .rpt-reset-btn {
        float: right;
    }
}

tr.rpt-new-row {
    padding: 0px !important;

    &:hover {
        background-color: #fff !important;
    }
}

// expanding table
// custom parent class
.pt-child-expand {
    margin-bottom: 0px !important;

}

.row-expansion-style {
    padding: 0px !important;
}

// drag and drop icon style
.pt-ico-dnd {
    color: #666;

    &:hover {
        border-radius: 4px;
        padding: 10px;
        border: 1px solid $toolbar-bg;
        background-color: $toolbar-bg;
    }
}

.pt-parameter-divide {
    border-top: 1px dashed #666;
}

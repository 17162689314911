// colours
$magenta: #cb39bc;
$purple: #42006a;
$blue: #2e7cf6;
$teal: #4fb6b2;
$plum: #760278;
$pt-black: #343a40;

$gradient-purple: linear-gradient(127.98deg, #3e0064 5.2%, #760278 102.75%);

$border-color: #bbb;
$border-color-active: $magenta;

$pt-blue-light:#d6ebff;
$pt-blue-dark:#1e5085;
$red: #ff004c;
$green:#1cff71;
$orange: #ffd76a;
$orange-lite: #fff2ad;
$orange-deep: #ff8c00; //#fa7f32
$light-purple-grey: #806a88;
$glow: #ffb039;
$toolbar_bg2: #e5e5e2;
$toolbar_bg: #eeeeee;
$pt-info-light: #bdbdbd;
$pt-light-grey: #f0f0f0;
$pt-med-grey: #969696;
$pt-mid-grey: #d8d8d8;

// for actouree display highlight:
$hilite: #f9f2ea;
$hilite-duo: #ebdec8; 

$editor-btn-col: desaturate(lighten($purple, 25%), 80%);
$magenta-btn-col: $magenta;

// for view data 
$view-data1: #f5ffe2;
$view-data2: #d0ff79;
$pt-dark-grey: #6c757d;
$pt-form-border: #cecece;
$pt-readonly-bg: rgb(251, 255, 191);

$pt-nav: #e5e5e2;

$pt-bg-dark: #270336;
$pt-bg-light: #aa15af;

$pt-alt: #791e86;
$pt-alt-text: #cdb8d6;

$pt-progress-bg-light: #aa15af;

$table-bg-head: desaturate(lighten($purple, 15%), 80%); // #604f80;
$table-bg-purple-light: desaturate(lighten($purple, 69%), 69%);
$table-teal-light: desaturate(lighten($teal, 28%), 5%);

.booking-editor {
    background-color: $toolbar_bg;
}

.booking-editor-tab > .accordion.card,
.accordion.booking-editor-tab > .card {
    border: none;
    margin-bottom: 1px;

    > .card-header {
        background: lighten($blue, 40%);
        border: 1px solid desaturate(lighten($blue, 20%), 20%);
        border-radius: 3px;

        &:hover {
            border-color: $blue;
        }

        &.active {
            border-color: $blue;
            border-radius: 3px 3px 0 0;

            &~.card-body {
                border: 1px solid desaturate(lighten($blue, 30%), 30%);
                border-radius: 0 0 3px 3px;
                border-width: 0 1px 1px;
            }
        }
    }
}

.booking-editor-top {
    box-sizing: border-box;
    padding: 6px;
}

.card-header {
    .btn {
        font-size: 1.2rem;
    }
}

.pt-editor-toast {
    background: #f5ffe2 !important;
    max-width: 100% !important;
    
    .toast-header {
        background: #d0ff79 !important;
    }
}

.event-list-table {
    margin-bottom: 0px !important;
}

.pt-ttbc {
    font-size: 120% !important;
    color: #666;
}

.event-list-row {
    background-color: $toolbar_bg;
    cursor: pointer;

    > td {
        vertical-align: middle;
    }

    &:hover {
        background: $orange-lite !important;
    }

    &.selected {
        background: $orange !important;
    }
}

.booking-event-filter-on {
    background: #f4e0fd;
}

.booking-event-filter-on-label {
    color: #973395;
}

.pt-accordion-dayschedule {
    color: $blue;
    font-size: 1.1em;
    
    &:hover {
        background: $blue !important;
        color: #fff !important;
        cursor: pointer;

        button {
            color: #fff !important;
            cursor: pointer;
        }
    }

    &.active {
        color: #fff !important;
        background: $blue !important;
    }

    &.open-by-default {
        background: $plum !important;
        cursor: default;
    }

    &.card-header {
        align-items: center;
        display: flex;
        font-weight: bold;

        [class*=" icon-"] {
            margin-right: 0.7rem;
        }
    }
}

.pt-accordion-main {
    @extend .btn-link; 
    font-size: 1.2em;
    padding-left: 24px !important;
    padding-top: 14px;
    padding-bottom: 14px;

    &:hover {
        background: $blue !important;
        color:#fff !important;
        cursor: pointer;
    }

    &.active {
        color: $blue !important;
        background: $pt-mid-grey !important;
    }
}

// .pt-inside-accordion {
//     border-left: 4px solid $orange-lite;
// }

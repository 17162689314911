.toolbar {
    align-items: center;
    background: $toolbar_bg2;
    box-sizing: border-box;
    display: flex;
    height: 46px; 
    margin: 0;
    padding: 0 10px;
    width: 100%;

    .btn {
        font-size: 1rem !important;
    }
}

// adjusting calendar buttons
.fc-button-primary {
  background-color: #fff !important;
  border-color: #c9c9c9 !important;
  border-radius: 4px;
  color:rgb(112, 112, 112) !important;
  font-size: 100%;
  
  &:focus {
    box-shadow: none !important;
  }

  &:hover {
    background-image: linear-gradient($blue, $blue);
    color:#fff !important;
  }

  &:not(:disabled) {
    &.fc-button-active,
    &:active {
      background-color: rgb(134, 134, 134) !important;
      border-color: #c9c9c9 !important;
      color: #fff !important;
    }
  }
}

.fc-button-primary:disabled {
  background-color: rgb(228, 228, 228) !important;
  border-color: #c9c9c9 !important;
  color:rgb(112, 112, 112);
  opacity: 1.0;
}

// adjusting calendar look
.fc-widget-header {
  border: 0px !important;
}

.fc th {
  border: 0px !important;
  color: #666;
  font-size: 140%;
  font-weight: normal !important;
  padding-left: 5px;
  text-align: left;
}

.fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
  color: #666;
  float: left !important;
  font-size: 1.2rem;
  padding: 5px;
}

//  adjusting calendar event styles
.fc-event-container {
  a {
    text-decoration: none;
    cursor: pointer;
  }
}

// adjusting calendar title style
.fc-toolbar {
  h2 {
    display: inline;
    font-size: 2rem !important;
    font-weight: normal !important;
  }

  .fc-center {
    text-align: center;
  }

  .fc-right {
    padding-left: 15px;
    text-align: right;
  }

  .fc-left {
    padding-right: 15px;
  }
}

.fc-view-container {
  .fc-event, .fc-event-dot {
    background-color: transparent;
    border-color: transparent;
    font-size: 1rem;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    overflow: hidden;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .booking-event {
    color: #000;
    height: 100%;
    margin: 0 1px;
    padding: 0.3rem;
    padding-bottom: 6px;

    .event-title {
      color: #333;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;
    }

    .event-title2 {
      background-color: rgba(255, 255, 255, 0.7);
      font-size: 85%;
      font-weight: 600;
      margin: 0;
      overflow: hidden;
      padding: 3px 3px 3px 3px;
      text-overflow: ellipsis;
      white-space: pre-wrap;
    }
  }

  // style time on single calendar
  .pt-calendar-time {
    font-size: 90%;
    margin: 0 !important;
    overflow: hidden;
    padding: 0 !important;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }

  // OLD booking event types
  .perf {
    background-color: #88e1fc;
  }

  .wksh {
    background-color: #fca440;
  }

  .wkshe {
    background-color: #fca440;
  }

  .wksht {
    background-color: #fca440;
  }

  .walk {
    background-color: #b9e65f;
  }

  .psd {
    background-color: #61b2ca;
  }
  
  .tl {
    background-color: #eef116;
  }

  // old day schedule events pastel colors
  
  // class for blocking events on the merged view
  .blocking-merged {
    @extend .blocking;
    background-color: #fff !important;
    min-height: inherit;
  }

  .non-blocking-merged {
    @extend .blocking;
    background-color: #fff !important;
    background-image: none;
    border: 0.3rem dashed rgb(133, 133, 133);
    min-height: inherit;
  }

  .wrapper {
    margin-top: 0.5rem;
    padding: 0.16rem;

    .project-title {
      color: #fff;
      font-size: 0.8rem;
      font-weight: bold;
      overflow: hidden;
      padding-left: 2px;
      text-transform: uppercase;
      text-overflow: ellipsis;
      white-space: pre-wrap;

      h4 {
        font-size: 1.2rem;
      }
    }

    .info-container {
      background: #fff;
      color: #000;
      font-size: 1rem;
      margin-top: 0.3rem;
      border-radius: 4px 4px 0px 0px;
    }

    .glyph-container svg {
      width: 1rem;
    }

    ul.events {
      list-style: none;
      margin-bottom: 0;
      padding-left: 0;
      
      .event-title {
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: pre-wrap;
        }

      > li {
        color: #333;
        font-size: 1rem;
        margin-top: 0.3rem;
        overflow: hidden;
        padding: 4px;
        text-overflow: ellipsis;
        white-space: pre-wrap;

        svg {
          margin-left: -1.6rem;
          width: 1.2rem;
        }
      }
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .glyph-container {
    padding-left: 0.2rem;
    white-space: pre-wrap;

    .isvg.loaded {
      &.done {
        fill: green;
      }

      &.todo {
        fill: orange;
      }

      &.na {
        fill: gray;
      }

      &.blocked {
        fill: red;
      }
    }
  }
}

.calendar-wrapper {
  padding-bottom: 60px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;

  &.merged-view {
    .fc-timeGridDay-button,
    .fc-timeGridWeek-button {
      pointer-events: none;
      opacity: 0.4;
    }
  }
}

// a day schedule event exists for a booking on merged view
.ds-event-exists > svg {
  fill: rgb(75, 235, 12);
}

// a day schedule event does not exist for a booking on merged view
.ds-event-absent > svg {
  fill: rgb(168, 168, 168);
}

// change background colour of month view past dates
.fc-other-month {
  background: #efefef !important;
}

// other month events style
.pt-other-month {
  opacity: 0.6;
}

.fc-scroller {
  height: auto !important;
  overflow: initial !important;
}

// making calendar nav buttons same size as other header buttons
.fc-right {
  .fc-button-group {
    font-size: 0.86em;
  }
}

.pt-badge-md {
  background: rgb(230, 230, 230) !important;
  font-size: 97%;
  font-weight: normal !important;
  padding:6px 8px 6px 8px !important;
}

.pt-badge-lg {
  font-size: 97%;
  padding: 2px !important;
  border: 1px solid rgb(235, 145, 28) !important;
}

.pt-recurs-single {
  border: 2px dotted rgb(255, 255, 255) !important;
  font-size: 95%;
  margin-bottom: 2px !important;
  padding-bottom: 1px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  padding-top: 1px !important;
}

.pt-recurs-multimode {
  border: 2px dotted rgb(255, 255, 255) !important;
  font-size: 73%;
  margin-right: 1px !important;
  margin-top: 1px !important;
  padding-bottom: 2px !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
  padding-top: 1px !important;
}

// overiding the now indicator style

.fc-now-indicator {
  border: 3px dashed $glow !important;
}

.fc-ltr .fc-time-grid .fc-now-indicator-arrow {
  border-bottom-color: transparent !important;
  border-top-color: transparent !important;
  border-width: 8px 0 8px 8px !important;
  left: 0 !important;
}

// fix the day calendar width issue
.fc-ltr .fc-axis {
  text-align: right;
  width: 10%;
}

// Accommodation events on the time grid
.fc-time-grid-event  {
  height: auto;
  padding-bottom: 15px;

  .accomm {
    height: auto;
    display: flex;
    text-align: left;

    .event-title {
      color: #333;
      flex-shrink: 3;
      margin: 0;
      overflow: hidden;
      padding-right: 4px;
      text-overflow: ellipsis;
      white-space: pre;
    }

    .pt-calendar-time  {
      flex-shrink: 0;
    }

    .event-title2 {
      flex-shrink: 0;
    }
  }
}

.fc-view-container .fc-time-grid-event-inset {
  box-shadow: none;
  padding-bottom: none !important;
}

// responsive calendar display

// only display the details of an event on wider screens 
.pt-cal-event-details {
  display: none;

  @media screen and (min-width: 660px) {
    display: block;
  }
}

.fc-timeGridWeek-view {
  .pt-cal-event-details {
    display: none;
  }
}

// display the event times as block on smaller screens
.pt-cal-event-times {
  display: block;

  @media screen and (min-width: 660px) {
    display: inline;
  }
}

.pt-calendar-icons {
  display: block;
  margin-bottom: 4px;

  span {
    vertical-align: middle;
  }

  @media screen and (min-width: 660px) {
    display: inline-block;
    float: right;
  }
}

// control bar 

.calendar-control-bar {
  align-items: center;
  display: flex;
  height: 34px;
  justify-content: space-between;

  .btn {
    background: $magenta;
    border-radius: 0.25rem;
    color: $white;
    line-height: 1;

    .ico-at-header {
      font-size: 1.5rem;

      &.adjust-spacing {
        margin-left: -0.7rem;
      }
    }
  }
}

.btn {
  &.previous {
    [class*="icon-rating"] {
      display: block;
      -webkit-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
              transform: rotate(-90deg);
    }
  }
  &.next {
    [class*="icon-rating"] {
      display: block;
      -webkit-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
              transform: rotate(90deg);
    }
  }
}

// Actouree calendar

.actouree-container {
  .fc-ltr .fc-dayGrid-view .fc-day-top {
    cursor: pointer;
    font-size: 1.2rem;
    height: 40px;

    .fc-day-number {
      color: $blue;

      &:hover {
        color: #0073e6;
        text-decoration: none;
      }
    }
  }
}

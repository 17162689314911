.actouree-header {
    align-items: center;
    background-color: $at-header-light !important;
    border-bottom: 1px solid #ddd;
    display: flex;
    height: $at-header-height;
    justify-content: space-between;
    width: 100%;
    z-index: 10;

    .date-container {
        padding: 0px;
        text-align: center;
        color: #ffffff;

        .at-monthyear {
            @include font-size(1.6rem);
            font-weight: bold;   
            @include media-breakpoint-down(xs) {
                display: block;
            }            
        }

        .at-daydate {
            @include font-size(1.6rem);
            margin-right: 0.2rem;
        }
    }

    .calendar-nav-button {
        border: none !important;
        border-radius: 0.5rem;
        color: #ffffff;
        font-size: 0.8rem;
        min-height: 50px;
        min-width: 50px;

        .adjust-spacing {
            margin-left: -1.25rem;
        }

        &:hover {
            background-color: $at-header-dark !important;
            border: none !important;
        }

        &.active {
            border: none !important;
            border-style: none !important;
            outline: none !important;
        }
    
        @media (min-width: 1200px) { 
            font-size: 1.2rem;
        }
    }

    @include media-breakpoint-down(sm) {
        .calendar-nav-button {
            min-width: 36px;

            .adjust-spacing {
                margin-left: -5.5px;
            }
        }

        .ico-at-header {
            font-size: 12px;
        }
    }
}

.at-navbar-title{
    padding: 0px;
    text-align: center !important;
    color: #ffffff;
    @include font-size(1.6rem);
    width: 100%;
    font-weight: bold;

}

.at-edit-mode {
    display: inline-block;
    background-color: rgb(197, 255, 131);
    color: #333333;
    border-radius: 5px;
    padding: 3px 7px;
    vertical-align: super;
    margin-right: 4px;
}

.at-edit-mode-label {
    @media (max-width: 300px) {
        display: none;
    }
}